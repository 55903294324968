<template>
  <b-modal hide-footer hide-header v-model="showModal" size="lg" centered>
    <div>
      <iframe
        width="800"
        height="230"
        :src="link"
        frameborder="0"
        allowfullscreen
      ></iframe>
      <div class="mt-3 d-flex w-100">
        <div class="mx-auto">
          <b-button @click="hide" variant="outline-primary">ยกเลิก</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    // userChatInfo: {
    //   type: Object,
    //   required: false
    // },
    // crmDetail: {
    //   type: Object,
    //   required: false
    // },
    // chatRoomId: {
    //   type: [String],
    //   required: false
    // }
  },
  data() {
    return {
      showModal: false,
      link: "https://google.com"
    };
  },
  created() {},
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .btn-main-primary,
::v-deep .btn-outline-primary {
  min-width: 150px !important;
}
@media (max-width: 767.98px) {
  ::v-deep .btn-main-primary,
  ::v-deep .btn-outline-primary {
    min-width: unset !important;
  }
}
</style>
