<template>
  <div class="div-input">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat" :class="className">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <div :class="['input-group', { 'input-with-btn-icon': icon }]">
        <div class="input-group-prepend">
          <b-dropdown
            :text="dropdownText"
            class="dropdown-input-custom"
            :disabled="isDisplay"
            variant="dropdown-customs"
          >
            <b-dropdown-item
              v-for="(item, i) in list"
              :key="i"
              @click="handleChangeDropdown(item, i)"
              >{{ item.value }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <input
          :class="['custom-input f-regular']"
          :type="type"
          :placeholder="placeholder"
          :name="name"
          :required="required"
          :value="value"
          :size="size"
          @input="handleInput"
          @change="onDataChange"
          @keypress="onkeypress"
          @keyup="onkeyup"
          @keydown="handleKeydown"
          ref="input"
          :maxlength="maxlength"
          :disabled="isDisplay"
        />
        <b-button
          v-if="icon"
          variant="outline-secondary"
          class="btn-icon"
          @click="handleBtnSubmit"
        >
          <font-awesome-icon :icon="icon" />
        </b-button>
      </div>
    </div>
    <span v-if="detail" class="text-desc">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">
        กรุณากรอกข้อมูลให้ครบถ้วน
      </span>
      <span class="text-error" v-else-if="v.integer == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >
      <span class="text-error" v-else-if="v.maxLength == false"
        >กรุณากรอกไม่เกิน {{ v.$params.maxLength.max }} อักษร</span
      >
      <span class="text-error" v-else-if="v.decimal == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >
      <span class="text-error" v-else-if="v.minValue == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: false,
      type: String
    },
    className: {
      required: false,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    maxlength: {
      required: false,
      type: [Number, String]
    },
    icon: {
      required: false,
      type: String
    },
    list: {
      required: true,
      type: Array
    },
    valueDropdown: {
      required: false,
      type: [Number, String]
    }
  },
  data() {
    return {
      dropdownText: "+66"
    };
  },
  created() {
    this.setDropdownText(this.list[0].value);
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    },
    handleInput($event) {
      this.$emit("input", $event.target.value);
    },
    onkeypress($event) {
      this.$emit("onkeypress", $event);
    },
    onkeyup($event) {
      this.$emit("onkeyup", $event.target.value);
    },
    handleBtnSubmit() {
      this.$emit("submit", this.value);
    },
    focus() {
      this.$refs.input.focus();
    },
    setDropdownText(val) {
      this.dropdownText = val;
    },
    handleKeydown(e) {
      if (this.type == "number" && e.keyCode == 109) {
        return e.preventDefault();
      }
      if (e.which === 40 || e.which === 38) {
        e.preventDefault();
      }
      this.$emit("onKeydown", e.target.value);
    },
    handleChangeDropdown(item, i) {
      if (item) {
        this.dropdownText = item.value;
        this.$emit("onChangeDropdown", item, i);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.div-input {
  margin-bottom: 15px;
  position: relative;
  /* white-space: nowrap; */
}
.input-custom {
  padding: 0px;
}
.input-custom input:disabled {
  background-color: #f5f5f5;
}
.input-custom label {
  color: #575757;
  font-size: 14px;
  margin-bottom: 2px;
}
.input-custom input {
  color: #575757;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 0 8px 8px 0;
  padding: 5px 10px;
}
.input-custom input[size="lg"] {
  height: 45px;
}
.input-custom input:focus {
  border: 1px solid #16274a;
}
.input-custom.error input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.input-with-btn-icon {
  display: flex;
  .custom-input {
    border-radius: 10px 0px 0px 10px !important;
  }
  .btn-icon {
    border-left: 0 !important;
    border-radius: 0px 10px 10px 0px;
  }
}
::v-deep .input-group {
  flex-wrap: nowrap !important;
  .btn-dropdown-customs {
    border: 1px solid #dbdbdb;
    border-radius: 8px 0 0 8px;
    border-right: 0;
  }
  ul.dropdown-menu {
    padding: 0;
    min-width: 60px;
    a {
      padding: 5px 10px;
    }
  }
}
@media (max-width: 767.98px) {
  /* .div-input {
    margin-top: 0;
  } */
  .input-custom label {
    font-size: 15px;
  }
}
</style>
